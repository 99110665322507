import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, toastAlert } from "@sellix/shared/components";
import { useTranslation } from "react-i18next";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import Search from "../../components/search";
import { getCDNLink, random, search } from "../../utils/config.tsx";
import { PureFileIcon } from "../../assets/icons.tsx";
import moment from "moment/moment";
import Table from "../../components/table";
import useMobile from "../../hooks/useMobile.ts";
import { IRconProductInfo } from "../../types/types.ts";
import {
  getRconProducts as getRconProductsAction,
  executeRconProductCommands as executeRconProductCommandsAction,
} from "./actions.ts";
import MobileRconProducts, { MobilePlaceholder } from "./mobile.tsx";

import "./index.css";
import Empty from "../../components/empty";

const FILTERS = [
  'uniqid',
  'title'
];

const RCONGaming = () => {

  const [loading, setLoading] = useState(true);
  const [executing, setExecuting] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [products, setProducts] = useState<IRconProductInfo[]>([]);
  const { t } = useTranslation("translation", { keyPrefix: "customer.rcon" });

  const isMobile = useMobile();

  const getRconProducts = useCallback(async() => {
    try {
      setLoading(true);
      const res = await getRconProductsAction();
      const productsInfo = res.data.products_rcon_gaming ?? [];
      setProducts(productsInfo);
    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setLoading(false);
    }
  }, [setProducts, setLoading]);

  const executeRconCommand = useCallback(async(invoiceId: number, productId: number) => {
    try {
      setExecuting(true)
      await executeRconProductCommandsAction(invoiceId, productId);
      const res = await getRconProductsAction(invoiceId, productId);
      const productsInfo = res.data.products_rcon_gaming ?? [];
      if (productsInfo.length > 0) {
        const productInfo = productsInfo[0];

        setProducts(products.map((prod) => {
          if (prod.invoice_id === productInfo.invoice_id && prod.id === productInfo.id) {
            return productInfo;
          }
          return prod;
        }));
      }
    } catch (error: any) {
      toastAlert("error", (error ? (error.error || error.message) : "") || "Server Error!");
    } finally {
      setExecuting(false);
    }
  }, [products, setProducts, setExecuting]);

  useEffect(() => {
    getRconProducts().catch(() => {});
  }, [getRconProducts]);

  const placeholder = useMemo(() => (
    [
      {
        accessorKey: 'invoice_uniqid',
        header: t('table.columns.info'),
        enableSorting: false,
        cell: () => <div>
          <div className="product-table-type">
            <div className="product-table-type-icon">
              <PureFileIcon/>
            </div>
            <div className="product-table-type-title">
              <div className="skeleton skeleton2 small" style={{ width: random(80, 120) }}/>
            </div>
          </div>
        </div>
      }, {
        accessorKey: 'uniqid',
        header: t('table.columns.id'),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: 'command_created_at',
        header: t('table.columns.purchased'),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: 'rcon_start_time',
        header: t('table.columns.startTime'),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }} />
      }, {
        accessorKey: 'command_status',
        header: t('table.columns.status'),
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(100) }}/>,
      },
      {
        accessorKey: 'actions',
        header: '',
        enableSorting: false,
        cell: () => <div className="skeleton skeleton7" style={{ width: random(70) }}/>,
      }
    ]
  ), [t]);

  const columns: ColumnDef<IRconProductInfo>[] = useMemo(() => [
    {
      accessorKey: 'invoice_uniqid',
      header: t('table.columns.info') as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IRconProductInfo, unknown>) => <>
        <div className="product-table-type">
          <div className="product-table-type-icon">
            {row.original.cloudflare_image_id
              ? <img src={getCDNLink({ id: row.original.cloudflare_image_id })} alt=""/>
              : <PureFileIcon/>}
          </div>
          <div className="product-table-type-title">
            <a rel="noreferrer" target="_blank" href={`/product/${row.original.uniqid}`}>{row.original.title}</a>
          </div>
        </div>
      </>
    }, {
      accessorKey: 'uniqid',
      header: t('table.columns.id') as string,
      enableSorting: false,
    }, {
      accessorKey: 'commands_created_at',
      header: t('table.columns.purchased') as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IRconProductInfo, unknown>) => {
        return row.original.commands_created_at
          ? moment(row.original.commands_created_at, "YYYY-MM-DD HH:mm:ss").format('DD MMM YYYY (HH:mm)')
          : null;
      }
    }, {
      accessorKey: 'rcon_start_time',
      header: t('table.columns.startTime') as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IRconProductInfo, unknown>) => {
        return row.original.rcon_start_time
          ? moment(row.original.rcon_start_time, "YYYY-MM-DD HH:mm:ss").format('DD MMM YYYY (HH:mm)')
          : null;
      }
    }, {
      accessorKey: 'command_status',
      header: t('table.columns.status') as string,
      enableSorting: false,
      cell: ({ row }: CellContext<IRconProductInfo, unknown>) => {
        const isFailed = row.original.commands_failed_count > 0;
        const isPending = !isFailed && row.original.commands_pending_count > 0;
        const isSuccess = !isFailed && !isPending && row.original.commands_success_count > 0;

        return <div className="flex gap-2">
          <div className={`badge ${isSuccess && 'badge-success'} ${isPending && 'badge-pending'} ${isFailed && 'badge-failed'}`}>
            {isSuccess && t('status.completed')}
            {isFailed && t('status.error')}
            {isPending && t('status.pending')}
          </div>
        </div>;
      }
    }, {
      accessorKey: 'actions',
      header: '',
      enableSorting: false,
      cell: ({ row }: CellContext<IRconProductInfo, unknown>) => {

        if (row.original.commands_pending_count === 0 && row.original.commands_failed_count === 0) {
          return null;
        }

        const status = row.original.commands.some((cmd) => {
          const startTime = moment(cmd.start_time);
          const now = moment();
          return cmd.execution_type === "MANUAL" && (cmd.status === "PENDING" || cmd.status === "FAILED") && startTime <= now
        });

        if (status) {
          return <div className="flex w-full justify-end">
            <Button
              variant="secondary"
              size="small"
              className="execute-command-btn"
              disabled={executing}
              onClick={() => executeRconCommand(row.original.invoice_id, row.original.id)}
            >
              {t('actions.execute')}
            </Button>
          </div>;
        }

        return null;
      }
    }
  ], [executing, t, executeRconCommand]);

  if (!loading && !products.length) {
    return <Empty isRCON />
  }

  let filteredProducts = products;
  if (searchKey) {
    filteredProducts = search(products, FILTERS, searchKey) as IRconProductInfo[];
  }

  return (
    <div className="rcon-gaming-screen">

      <div className="screen-header items-start pt-0">
        <div className="screen-filters gap-2">
          <Search onChange={setSearchKey} search={searchKey} />
        </div>
      </div>

      <div className="rcon-gaming-screen-container">
        {isMobile
          ? null
          : loading
            ? <Table data={[{}, {}, {}, {}, {}, {}]} isPlaceholder columns={placeholder} />
            : (
              <Table
                clearFilters={() => {
                  setSearchKey("");
                }}
                cursorPointer
                data={filteredProducts}
                columns={columns}
              />
            )
        }

        {isMobile && loading && <MobilePlaceholder />}
        {isMobile && !loading && (
          <MobileRconProducts
            data={filteredProducts}
            clearFilters={() => {
              setSearchKey("")
            }}
            executing={executing}
            executeRconCommand={executeRconCommand}
          />
        )}
      </div>
    </div>
  );
}

export default RCONGaming;