import React, { ReactNode } from "react";
import { Button } from "@sellix/shared/components";
import Modal from "../../../../components/modal";
import { ToastWarning } from "../../../../assets/icons";
import { useParams } from "react-router";
import { toastAlert } from "@sellix/shared/components";
import { cancelBill } from "../../actions";
import { useTranslation } from "react-i18next";
import { ICancelBill, IResponse } from "../../../../types/types";

// import "../../../invoice/modals/index.css";


const CancelModal = ({ initializeData, openModal, closeModal }: { initializeData: () => void, openModal: boolean, closeModal: () => void }): ReactNode => {

  const { id } = useParams();
  const { t } = useTranslation('translation', { keyPrefix: "customer.subscriptions.details.cancelModal" });

  const cancelSubscription = async(): Promise<any> => {
    await cancelBill(id)
      .then((res: IResponse<ICancelBill>) => {
        toastAlert("success", res.message || "Subscription canceled successfully!");
        closeModal();
        initializeData();
      })
      .catch(err => {
        toastAlert("error", (err && err.error) || 'Something went wrong!')
      })
  }

  return (
    <Modal open={openModal} closeModal={closeModal} skipClose confirm>
      <div className="modal-container">
        <div className="modal-icon info">
          <ToastWarning />
        </div>
        <div className="modal-header" style={{ textAlign: "center" }}>

          {t('title')}
        </div>
        <div className="modal-description">
          <span>
            {t('message')}
          </span>
        </div>
        <div className="modal-footer">
          <Button variant="secondary" size="large" onClick={closeModal}>{t('closeButton')}</Button>
          <Button className="delete" variant="warning" onClick={cancelSubscription}>{t('confirmButton')}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default CancelModal;
