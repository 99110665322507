import customerApi from "../../utils/customer-api";
import { formData } from "../../utils/config"
import { ICancelBill, IResponse, ISubscriptionWrapper } from "../../types/types";
import api from "../../utils/api.tsx";

export const getRecurring = async(): Promise<IResponse<ISubscriptionWrapper>> => {
  const res = await customerApi.get<never, IResponse<ISubscriptionWrapper>>(`self_customer/invoices/recurring_bills`);

  if (res.status === 200) {
    return res
  } else {
    console.error(res);
    throw res;
  }
}

export const getRecurringById = async(id?: string): Promise<IResponse<ISubscriptionWrapper>> => {
  const res = await customerApi.get<never, IResponse<ISubscriptionWrapper>>(`invoices_customer/recurring_bill/${id}`)

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}


export const cancelBill = async(id?: string): Promise<IResponse<ICancelBill>> => {
  const res = await customerApi.post<never, IResponse<ICancelBill>>(`invoices_customer/recurring_bill/cancel`, formData({ id }));

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }

}


export const evmSpenders = async(): Promise<IResponse<any>> => {
  const res = await api.get<never, IResponse<ICancelBill>>(`evm/approved_spenders`);

  if (res.status === 200) {
    return res;
  } else {
    console.error(res);
    throw res;
  }
}
