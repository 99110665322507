import React, { useMemo, useState } from "react";
import Pagination from "../../components/pagination";
import { getCDNLink } from "../../utils/config";
import { useCustomerContext } from "../../provider/CustomerContext";
import { CopyIcon, PureFileIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@sellix/shared/components";
import { IProductsNormalized } from "../../types/types";
import { IVariant } from "./table";
import { FIAT_SYMBOLS } from "@sellix/shared/configs";

export const calculatePrice = (item: IProductsNormalized, shopPercent: number, specialPercent: number) => {

  const { price_variants, price_discount,  price_display, affiliate_revenue_percent } = item;

  const currency = FIAT_SYMBOLS[item.currency];

  const variants = price_variants;

  let minPrice: any = null;
  let maxPrice: any = null;

  if(variants) {
    variants.forEach((variant: IVariant) => {
      if(minPrice === null || variant.price < minPrice) {
        minPrice = variant.price;
      }
      if(maxPrice === null || variant.price > maxPrice) {
        maxPrice = variant.price;
      }
    });
  }

  const productPrice = minPrice || price_display;
  let price = price_discount ? (productPrice - productPrice * price_discount / 100) : productPrice
  const minPriceDiscount = price_discount ? (minPrice - minPrice * price_discount / 100) : minPrice;
  const maxPriceDiscount = price_discount ? (maxPrice - maxPrice * price_discount / 100) : maxPrice;
  price = (+price).toFixed(8);
  price = parseFloat(price);


  let percent = affiliate_revenue_percent || 0;

  if(+specialPercent) {
    percent = specialPercent;
  } else {
    if(percent && +percent > 0) {
      // eslint-disable-next-line no-self-assign
      percent = percent;
    } else if((percent === -1 || percent === 0) && shopPercent) {
      percent = shopPercent;
    } else {
      percent = 0
    }
  }

  const total = (p: number) => {
    return ((p * +percent) / 100).toFixed(2)
  };

  if(variants) {
    return <div>
      {currency}{total(minPriceDiscount)} - {currency}{total(maxPriceDiscount)}
    </div>
  } else {
    return <div>
      {/* {pay_what_you_want ? <span>{t('row.chooseYourPrice')}</span> : null} */}
      {currency}{total(price)}
    </div>
  }
}


export const getPercent = (item: IProductsNormalized, shopPercent: number, specialPercent: number) => {

  let percent = item.affiliate_revenue_percent || 0;

  if(+specialPercent) {
    percent = `${specialPercent}%`
  } else {
    if(percent && +percent > 0) {
      percent = `${percent}%`
    } else if((percent === -1 || percent === 0) && shopPercent) {
      percent = `${shopPercent}%`
    } else {
      percent = "-"
    }
  }

  return percent;
}

const MobileProducts = ({ data }: { data: IProductsNormalized[] }) => {

  const { customerInfo } = useCustomerContext();

  const { t } = useTranslation('translation', { keyPrefix: "customer.affiliates" });

  const SIZE = 3;

  const [currentPage, setCurrentPage] = useState<number | '...'>(1);
  const currentTableData = useMemo(() => {
    const firstPageIndex = (+currentPage - 1) * SIZE;
    const lastPageIndex = firstPageIndex + SIZE;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, SIZE]);

  if(!data.length || !customerInfo) {
    return null;
  }

  return (
    <div>
      {currentTableData.map((item, key) => {

        const { isSubscription, pay_what_you_want, title, uniqid, cloudflare_image_id } = item;

        const percent = getPercent(item, customerInfo.shop_affiliate_revenue_percent, customerInfo.special_affiliate_revenue_percent);
        const youWillReceive = calculatePrice(item, customerInfo.shop_affiliate_revenue_percent, customerInfo.shop_affiliate_revenue_percent);

        return <div className="table-mobile-card" key={key}>

          <div className="table-mobile-card-header">
            <div className="flex">
              <div className="table-mobile-card-header-icon">
                {cloudflare_image_id ? <img src={getCDNLink({ id: cloudflare_image_id })} alt=""/> : <PureFileIcon />}
              </div>

              <div className="table-mobile-card-header-main">
                <a href="/">{title}</a>
                <div>{uniqid}</div>
              </div>

              {pay_what_you_want ?
                <div className="mx-2 badge badge-sellix">
                  {t('row.chooseYourPrice')}
                </div>
                : null}
              {isSubscription ?
                <div className="mx-2 badge badge-sellix">
                  {t('row.subscription')}
                </div>
                : null}
            </div>

            <Tooltip tooltipText="Copy referral link" name="affiliate-copy" copiedText={`https://${window.location.host}/product/${uniqid}?rcid=${customerInfo.id}`}>
              <div className="affiliate-screen-button">
                <CopyIcon color="var(--purple6)" /> {t('row.getLink')}
              </div>
            </Tooltip>
          </div>

          <div className="table-mobile-card-body">
            <div className="table-mobile-card-body-list">
              <div>
                <div>{t('table.columns.commission')}</div>
                <div>{percent}</div>
              </div>
              <div>
                <div>{t('table.columns.youWillReceive')}</div>
                <div>{youWillReceive}</div>
              </div>
            </div>
          </div>

        </div>
      })}

      <Pagination
        currentPage={+currentPage}
        totalCount={data.length}
        pageSize={SIZE}
        onPageChange={setCurrentPage}
      />

    </div>
  );
};

export default MobileProducts;
