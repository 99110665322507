import React from "react";
import Empty from "../../components/empty";
import { useCustomerContext } from "../../provider/CustomerContext";
import MobileProducts from "./mobile-products";
import useMobile from "../../hooks/useMobile";
import AffiliateTable from "./table";
import { Tooltip } from "@sellix/shared/components";

import "./index.css";
import { CopyIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../provider/AuthContext.ts";

const Affiliate = () => {

  const { customerPortalDomain } = useAuthContext();
  const { affiliatedProducts, customerInfo } = useCustomerContext();
  const { t } = useTranslation('translation', { keyPrefix: "customer.affiliates" });
  const isMobile = useMobile();

  const isEmpty: boolean = !affiliatedProducts.length;

  if(!customerInfo) {
    return null
  }

  if(isEmpty) {
    return <Empty isProducts />
  }

  return <div className="affiliate-screen">

    <div className="affiliate-top-link">
      <div className="affiliate-top-link-title">
        {t("storeAffiliateLink")}
      </div>

      <Tooltip noArrow tooltipText={t("copyShopLink")} successText={t("copied")} copiedText={`https://${customerPortalDomain ? customerPortalDomain : window.location.host}?rcid=${customerInfo.id}`} name="referral-link">
        <div className="affiliate-top-link-input">
          <span>{`${customerPortalDomain ? 'https://' : ''}${customerPortalDomain ? customerPortalDomain : window.location.host}?rcid=${customerInfo.id}`}</span> <CopyIcon color="var(--purple6)"/>
        </div>
      </Tooltip>

    </div>

    {isMobile ?
      <MobileProducts data={affiliatedProducts} /> :
      <AffiliateTable data={affiliatedProducts} customerInfo={customerInfo} />
    }
  </div>
}

export default Affiliate;
